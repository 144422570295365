import employees from "./employees";

const AboutUs = () => {
    return (
        <div
            className="bg-background-radial-gradient pb-4 md:pb-10 pt-18 md:pt-40 w-full flex flex-col md:flex-row justify-center items-center"
            id="aboutus"
        >
            <h1 className=" md:hidden mb-3 font-extrabold text-[#E9FF00] text-28-56 text-[28px] text-stroke-3 drop-shadow-[2px_2px_0_rgb(255,255,255)] md:drop-shadow-[4px_4px_0_rgb(255,255,255)]">
                ABOUT US
            </h1>
            <div className="relative group flex gap-x-3 md:gap-x-10 w-[90%] md:w-[80%] ">
                <div className="absolute -top-[10%] -left-[20%] w-[175px] md:w-[455px] h-[125px] md:h-[455px] bg-[#D622DA95] rounded-full blur-[147px]" />
                <div className=" bg-[#050505] rounded-2xl overflow-hidden p-2 md:p-6 text-center max-w-[372px] shadow-[-1px_-1px_0_#ffffff2f] z-10">
                    <div className="flex items-center md:block">
                        <div className="w-25 md:w-full">
                            <img
                                src="/assets/team/keven.jpg"
                                alt=""
                                className="rounded-lg overflow-hidden shadow-[-1px_-1px_0px_#ffffff2f]  md:w-full"
                            />
                        </div>

                        <div>
                            <h2 className="bg-gradient-to-r from-[#fff]  to-[#ffffff5f]  inline-block text-transparent bg-clip-text text-16-24 font-medium mt-3 md:mt-8">
                                Keven Lai
                            </h2>
                            {/* <p className="text-[#ffffffb1] font-bold text-12-16 my-1 md:my-3">CEO</p> */}
                            <p className="text-[#ffffff]  text-6-12 md:mt-1 mb-1 md:mb-3">CEO</p>
                            <p className="text-[#ffffff71] text-12-16">
                                Keven Lai is a Silicon Valley serial entrepreneur.
                            </p>
                        </div>
                    </div>
                    <p className="md:hidden bg-gradient-to-r from-[#fff]  to-[#ffffff5f] text-start  mt-2  inline-block text-transparent bg-clip-text  font-medium text-12-24 md:leading-[48px] md:max-w-[985px] tracking-tight ">
                    Keven Lai has spent over 30 years developing in Silicon Valley, witnessing firsthand the region’s remarkable transformation from a hardware-driven hub to a global leader in software innovation. Over a decade, he successfully led both BoldData Technology and Crown Micro to reach a combined annual revenue of $1 billion through strategic global marketing and sales operations spanning more than 10 countries.
                    </p>
                    <p className="md:hidden bg-gradient-to-r  from-[#fff]  to-[#ffffff5f] text-start  mt-2  inline-block text-transparent bg-clip-text  font-medium text-12-24 md:leading-[48px] md:max-w-[985px] tracking-tight ">
                    As a seasoned serial entrepreneur, Keven remains deeply engaged with the ongoing AI revolution within the broader blockchain technology trend. He has a sharp vision for the future, recognizing that the convergence of Web3 and AI will fundamentally reshape the social network economy and revolutionize digital asset management, paving the way for a more decentralized and intelligent digital society.
                    </p>
                </div>
                <div className="z-10">
                    <h1 className="hidden md:block font-extrabold text-[#E9FF00] text-28-56 text-[28px] text-stroke-3 drop-shadow-[2px_2px_0_rgb(255,255,255)] md:drop-shadow-[4px_4px_0_rgb(255,255,255)]">
                        ABOUT US
                    </h1>
                    <div className="hidden md:block bg-[#050505] p-8 rounded-2xl duration-500 group-hover:translate-y-[25%] shadow-[-1px_-1px_0_#ffffff2f] mt-10">
                        <p className="bg-gradient-to-r from-[#fff]  to-[#ffffff5f]  inline-block text-transparent bg-clip-text  font-medium text-12-20 max-w-[985px] tracking-tight">
                        Keven Lai has spent over 30 years developing in Silicon Valley, witnessing firsthand the region’s remarkable transformation from a hardware-driven hub to a global leader in software innovation. Over a decade, he successfully led both BoldData Technology and Crown Micro to reach a combined annual revenue of $1 billion through strategic global marketing and sales operations spanning more than 10 countries.
                        </p>
                        <p className="bg-gradient-to-r mt-2 from-[#fff]  to-[#ffffff5f]  inline-block text-transparent bg-clip-text  font-medium text-12-20 max-w-[985px] tracking-tight">
                        As a seasoned serial entrepreneur, Keven remains deeply engaged with the ongoing AI revolution within the broader blockchain technology trend. He has a sharp vision for the future, recognizing that the convergence of Web3 and AI will fundamentally reshape the social network economy and revolutionize digital asset management, paving the way for a more decentralized and intelligent digital society.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
