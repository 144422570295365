import Story from "../components/Story";
import Faq from "../components/Faq";
// import Story from "../components/Story"
import WhatIsFunsAI from "../components/WhatIsFunsAI";
import OurEcoSystem from "../components/OurEcoSystem";
import Compliance from "../components/Compliance";
import AIManifesto from "../components/AIManifesto";
import AboutUs from "../components/AboutUs";
import FoundingTeam from "../components/FoundingTeam";
import Footer from "../components/Footer";
import StickyBar from "../components/StickyBar";
import { useState } from "react";
import Universe from "../components/Universe";
import Partners from "../components/Partners";
import News from "../components/News";

const Home = () => {
    return (
        <div className="">
            <StickyBar />
            <WhatIsFunsAI />
            <OurEcoSystem />
            <Compliance />
            <AIManifesto />
            <Universe />
            <Story />
            <Faq />
            <AboutUs />
            <FoundingTeam />
            <News />
            <Partners />
            <Footer />
        </div>
    );
};

export default Home;
