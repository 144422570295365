import React from "react";
import StoryCard from "./StoryCard";
import StickyBar from "./StickyBar";

const News = () => {
    let data1 = 
        "Funs.AI is excited to announce the launch of its new socialFi platform, which will be available on the BASE Chain. This platform will provide a range of features designed to enhance the social experience for users, including a social relationship recommendation system, AI social agents, and a secure and trustworthy environment for creators to connect with their audience.";
    let data2 = 
        "The platform will feature a range of tools and features designed to help creators build and manage their social presence, including a social relationship recommendation system, AI social agents, and a secure and trustworthy environment for creators to connect with their audience.";
    return (
        <div
            id="news"
            className="relative bg-background-radial-gradient pb-16 h-[100vh]  md:min-h-[900px] max-w-[100vw] overflow-hidden"
        >
        
            <img className="h-[90vh] pt-16" src="/assets/clouds/clouds-group.png" alt="cloudsImage" />
            <img
                src="/assets/stars.svg"
                alt=""
                className="absolute top-[10%] right-[10%]  drop-shadow-[4px_4px_4px_rgb(255,255,255)]"
            />
            <img
                src="/assets/stars.svg"
                alt=""
                className="absolute top-[45%] left-[15%] drop-shadow-[4px_4px_4px_rgb(255,255,255)]"
            />
            <img
                src="/assets/stars.svg"
                alt=""
                className="absolute top-[85%] left-[5%] drop-shadow-[4px_4px_4px_rgb(255,255,255)]"
            />
            <img
                src="/assets/stars.svg"
                alt=""
                className="absolute top-[75%] right-[5%] drop-shadow-[4px_4px_4px_rgb(255,255,255)]"
            />

            <div className="absolute inset-0 pt-16">
                        <div className="flex justify-center items-center gap-x-3">
                            <img src="/assets/clouds/shout2.png" className="w-[96px] h-[96px]" alt="" />
                            <h1 className=" font-extrabold z-20 text-center text-[#E9FF00] text-28-56 text-stroke-3 drop-shadow-[2px_2px_0_rgb(255,255,255)] md:drop-shadow-[4px_4px_0_rgb(255,255,255)] ">
                                NEWS
                            </h1>
                            <img src="/assets/clouds/shout1.png" className="w-[96px] h-[96px]" alt="" />
                        </div>

                <div className="flex flex-col md:flex-row gap-y-4  md:gap-x-10 mx-auto w-fit mt-6">
                <div className=" md:flex-1 flex justify-center items-center">
                        <div className=" md:w-[65%] text-center md:text-start">

                            <p className="font-medium text-white text-12-20 mt-2 md:mt-5">
                            <span className="font-bold text-[#d4b8d7ff]">
                                    <a href="" target="_blank" rel="noopener noreferrer" className="underline">
                                        03/28/2025 Funs.AI Trial Version Receives 200+ Key Feedback Submissions from Early Trial Users
                                    </a>
                                    </span> <br/>
                                    Since launching its trial version on March 1, 2025, Funs.AI has attracted 321 users and generated over 3,000 social engagements, including posts, likes, comments, shares, follows, and messages — but the most valuable outcome has been the 200+ detailed user feedback submissions.
                                    <br/>
Early trial users provided insightful bug reports, feature suggestions, and improvement ideas that are now actively shaping the platform. To thank the community, Funs.AI has distributed thousands of token incentives to users contributing to the ecosystem through engagement and feedback.
<br/>
The team is actively reviewing and fixing all input as Funs.AI moves closer to its next development — and even launching — phase, aiming to become a leading SocialFi platform within the next three years.
 <br/>
                                <br/>
                                <span className="font-bold text-[#d4b8d7ff]">
                                    <a href="https://funs-ai.medium.com/funs-ai-presents-strategic-vision-to-wi-harper-group-receives-high-praise-from-venture-capital-06c9cd927359" target="_blank" rel="noopener noreferrer" className="underline">
                                        03/24/2025 Funs.ai Presents Strategic Vision to WI Harper Group, Receives High Praise from Venture Capital Veteran Peter Liu
                                    </a>
                                </span> 
                                <br/>
                                <br/>
                                <span className="font-bold text-[#d4b8d7ff]">
                                    <a href="https://funs-ai.medium.com/funs-ai-socialfi-proof-of-contribution-poc-model-c7b029137378" target="_blank" rel="noopener noreferrer" className="underline">
                                        02/27/2025 Funs.AI SocialFi Proof of Contribution (PoC) Model
                                    </a>
                                </span> 
                                <br/>
                                <br/>

                                <span className="font-bold text-[#d4b8d7ff]">
                                    <a href="https://funs-ai.medium.com/funs-ai-releases-ai-manifesto-empower-creativity-and-trust-75857deb6e5d" target="_blank" rel="noopener noreferrer" className="underline">
                                        11/29/2024 Funs.AI Releases AI Manifesto: Empower Creativity and Trust in Social Networking
                                    </a>
                                </span> 
                                <br/>
                                <br/>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="absolute top-[10%] -right-[10%] w-[225px] md:w-[455px] h-[225px] md:h-[455px] bg-[#d722dade] rounded-full blur-[247px]" />
        </div>
    );
};

export default News;
