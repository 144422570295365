const employees = [
    {
        image: "/assets/team/ricardo.png",
        name: "Richard Quan",
        position: "COO",
        quote: "Richard is an experienced operations executive with a proven track record of optimizing business processes for efficiency and growth.",
        bio: [
            "Richard holds a master's degree in computer science and has 20 years of experience in software development and engineering. He previously served as the Software Development Manager at Ixia. Leading technical teams in Silicon Valley, China, and India, Ricardo has developed applications utilized by renowned global enterprises such as Wells Fargo bank, Nasdaq, AT&T, those telecommunications and financial companies. Currently, he is focusing on artificial intelligence and blockchain technology. Ricardo also rewarded a certified Scrum Master, bringing agile development expertise to the team. Ricardo’s expertise has significantly boosted Knowpia’s operational efficiency.",
        ],
    },
    {
        image: "/assets/team/steve.png",
        name: "Steve Lin",
        position: "CTO",
        quote: "Steve Lin is a Silicon Valley serial entrepreneur who successfully co founded Funs.AI.",
        bio: [
            "Steve possesses outstanding leadership skills, having led a development team of 50 individuals and held positions such as Technical Director and General Manager. His technical expertise spans various platforms, operating systems, network protocols, and programming languages, with a particular focus on the field of artificial intelligence. Steve excels in the application of ChatGPT, knowledge graphs, and image algorithms. His achievements also include multiple awards in website and application development competitions.",
        ],
    },


    {
        image: "/assets/team/wilson.png",
        name: "Wilson Ye",
        position: "PM",
        quote: "Wilson brings valuable expertise in blockchain and security to the Funs.AI team.",
        bio: [
            "Wilson holds a master’s degree in computer science and brings over a decade of experience in software development & management within the cybersecurity sector, covering mobile, IoT, and blockchain technologies. He is currently focused on blockchain project management, including crypto compliance research, blockchain economic model design, and the development and delivery of Web3 projects. \nWilson previously served as the Engineering Director at Trustlook, an AI-driven cybersecurity startup based in Silicon Valley, where he led the implementation of several key security initiatives. Trustlook has secured over $30 million in venture capital funding.",
        ],
    },

    {
        image: "/assets/team/orlando.png",
        name: "Emanuel Orlando",
        position: "Legal Counselor",
        quote: "Orlando is a compassionate counselor dedicated to supporting the well-being and growth of the Funs.AI team.",
        bio: [
            "Emanuel is an experienced corporate and securities attorney specializing in the industry of blockchain technology. His law firm is a first-mover in structuring and handling S.E.C.-compliant Token Sales both domestically and internationally (including Reg A+ offerings, S-1 offerings, offshore ICOs and innovative dual token structures). He also has a network of highly qualified transactional tax advisors and strategic advisors that routinely support our clients. He is licensed to practice law in the state of California.",
        ],
    },
    
    {
        image: "/assets/team/duan.png",
        name: "Sherman Tuan",
        position: "Advisor Director",
        quote: "Duan is a seasoned technology advisor with a passion for innovation and emerging technologies.",
        bio: [
            "Independent Board Member of Super Micro Computer Inc.",
            "Founder & CEO of AboveNet Communications Inc. (acquired by Zayo Group for $2.2 billion)",
            "Founder & CEO of TelTel Network Inc.",
        ],
    },

    {
        image: "/assets/team/matt.png",
        name: "Matthew Swansen",
        position: "Investment Advisor",
        quote: "I believe the intersection of finance and technology is where the most transformative opportunities lie. ",
        bio: [
            "Matthew, an NYU Stern MBA graduate, blends finance and technology expertise with an entrepreneurial mindset. At Adaptor Capital, he focused on public internet and software investments. A published researcher with multiple first-author works, he offers deep financial insights and emerging tech knowledge.",
        ],
    },
    
    {
        image: "/assets/team/tord.png",
        name: "Tord Doennum",
        position: "Marketing Advisor",
        quote: "Tord is a visionary marketing strategist with a passion for driving brand awareness and customer engagement.",
        bio: [
            "Tord is a serial entrepreneur with experience in project management in high-growth startups in the tech industry and has had the pleasure of assisting multiple companies in identifying product-market fit, as well as securing early-stage funding.",
            "He has experience in both European and American startups and has honed my skills in project management, business strategy, finance, and marketing through my own company. He is meticulous and systematic with strong analytical and interpersonal skills.",
        ],
    },

    {
        image: "/assets/team/cha.png",
        name: "CHA",
        position: "Digital Art Advisor",
        quote: "CHA is a talented artist whose creativity and passion for art enrich the Funs.AI platform.",
        bio: [
            "Graduating from a renowned university in California, CHA holds a Master's degree in Fine Arts. CHA boasts over 30 years of experience in art creation and university teaching.",
            "With his unique artistic vision, CHA plays a key role in shaping the visual identity of Funs.AI. He is dedicated to creating captivating artwork that enhances user experience and reflects the essence of the platform. His creativity and passion for art inspire the Funs.AI team to push boundaries and explore new artistic horizons.",
        ],
    },
    {
        image: "/assets/team//phillip.png",
        name: "Phillip Lai",
        position: "Community Manager",
        quote: "Phillip is committed to fostering a vibrant and engaged community within the Funs.AI platform.",
        bio: [
            "With his expertise in community building and design, Phillip is dedicated to creating meaningful connections and experiences for Funs.AI users. He works tirelessly to cultivate a supportive and inclusive community where members can share ideas, collaborate on projects, and explore their creativity. His passion for community engagement drives the growth and success of the Funs.AI platform.",
        ],
    },
];

export default employees;
